<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <span class="bt rr">场所名称：</span>
            <input type="text" v-model="site_name" class="input zczz" placeholder="无场所名称" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">场所地址：</span>
            <input type="text" v-model="site_address" class="input zczz" placeholder="无场所地址" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">法人姓名：</span>
            <input type="text" v-model="law_principal_name" class="input zczz" placeholder="无法人姓名" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">统一编码：</span>
            <input type="text" v-model="permit_code" class="input zczz" placeholder="无统一编码" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">联系姓名：</span>
            <input type="text" v-model="principal_name" class="input zczz" placeholder="无联系姓名" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">联系证件：</span>
            <input type="text" v-model="principal_certificate_id" class="input zczz" placeholder="无联系证件" readonly/>
          </div>
          <div class="item" v-if="this.type==='1'">
            <span class="bt rr">联系电话：</span>
            <input type="number" v-model="principal_tel" class="input zczz" placeholder="无联系电话" readonly/>
          </div>
          <div class="item">
            <span class="bt rr">场所别名：</span>
            <input type="text" v-model="site_memo" class="input zczz" placeholder="场所别名信息" />
          </div>
          <div class="item">
            <span class="bt rr">法人电话：</span>
            <input type="text" v-model="site_memo1" class="input zczz" placeholder="法人电话信息" />
          </div>
          <div class="item">
            <span class="bt rr">负责电话：</span>
            <input type="text" v-model="site_memo2" class="input zczz" placeholder="负责人电话信息" />
          </div>
          <div class="item">
            <span class="bt rr">备注信息：</span>
            <input type="text" v-model="site_memo3" class="input zczz" placeholder="备注信息" />
          </div>
          <div class="item">
            <span class="bt rr t">定位经度：</span>
            <input type="text" v-model="longitude" class="input zczz" placeholder="经度信息" />
            <img src="@/assets/gps.png" @click="gomap" class="icon" />
          </div>
          <div class="item">
            <span class="bt rr">定位纬度：</span>
            <input type="text" v-model="latitude" class="input zczz" placeholder="纬度信息" />
          </div>
          <div class="item1">
            <img :src="pbimgurl" @click="photograph_zz" class="imgzz" />
          </div>
        </div>
        <div class="button primary mm" @click="submit">提交</div>
        <div class="button1 primary mt" @click="goback">取消</div> 
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {

    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;

    this.query();   
   
    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });

  },
  timer: '',
  data() {
    return {
      state:false,
      type: '',
      unionid: '',
      srole: true,
      site_code: '',
      site_name: '',
      site_address: '',
      law_principal_name: '',
      permit_code: '',
      principal_name: '',
      principal_tel: '',
      principal_certificate_id: '',
      site_memo: '',
      site_memo1: '',
      site_memo2: '',
      site_memo3: '',
      longitude: '',
      latitude: '',
      longitude_ls: '',
      latitude_ls: '',
      pbimgurl:'/img/scpb.png',
      pic_name: ''
    };
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    goback() {
      this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    gomap(){
      this.$router.replace({path: '/boss/modify_map', query: {
          type:this.type,
          unionid:this.unionid,
          srole:this.srole,
          site_memo:this.site_memo,
          site_memo1:this.site_memo1,
          site_memo2:this.site_memo2,
          site_memo3:this.site_memo3,
          lng:this.longitude_ls,
          lat:this.latitude_ls
        }} );
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_getbasic',
        qs.stringify({})
      );
      if (data.flag === '0') {
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;

        this.site_code = testobj.site_code;
        this.site_name = testobj.site_name;
        this.site_address = testobj.site_address;     
        this.law_principal_name = testobj.law_principal_name;  
        this.permit_code = testobj.permit_code;  
        this.principal_name = testobj.principal_name;  
        this.principal_tel = testobj.principal_tel;
        this.principal_certificate_id = testobj.principal_certificate_id;
        this.site_memo = testobj.site_memo;
        this.site_memo1 = testobj.site_memo1;
        this.site_memo2 = testobj.site_memo2;
        this.site_memo3 = testobj.site_memo3;       
        this.longitude = testobj.longitude;
        this.latitude = testobj.latitude;
        this.longitude_ls = testobj.longitude_ls;
        this.latitude_ls = testobj.latitude_ls;
        if (testobj.pic_name1 !== null && testobj.pic_name1 !== undefined && testobj.pic_name1.length !== 0) {
          this.pbimgurl = testobj.pic_name1;
        } 

      }else{
        Toast(data.message);
      }

      let lng = this.$route.query.lng;
      if (lng !== null && lng !== undefined && lng.length !== 0) {
        this.longitude = lng;
        //alert(this.longitude);
      }
      let lat = this.$route.query.lat;
      if (lat !== null && lat !== undefined && lat.length !== 0) {
        this.latitude = lat;
      }

      let site_memo = this.$route.query.site_memo;
      if (site_memo !== null && site_memo !== undefined && site_memo.length !== 0) {
        this.site_memo = site_memo;
      }

      let site_memo1 = this.$route.query.site_memo1;
      if (site_memo1 !== null && site_memo1 !== undefined && site_memo1.length !== 0) {
        this.site_memo1 = site_memo1;
      }

      let site_memo2 = this.$route.query.site_memo2;
      if (site_memo2 !== null && site_memo2 !== undefined && site_memo2.length !== 0) {
        this.site_memo2 = site_memo2;
      }

      let site_memo3 = this.$route.query.site_memo3;
      if (site_memo3 !== null && site_memo3 !== undefined && site_memo3.length !== 0) {
        this.site_memo3 = site_memo3;
      } 

    },
    photograph_zz(){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID         
                this.uploadImg_zz(serverId);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      
    },
    async uploadImg_zz(serverId) {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_pbpic',
        qs.stringify({
          site_code: this.site_code,
          pbpicid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        /*this.zzimgurl = data.data.pic_zz;
        this.pic_name = data.data.pic_name;*/
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
        this.pbimgurl = testobj.pic_zz;
        this.pic_name = testobj.pic_name;
      }else {
        Toast(data.message);
      }    
    },
    async submit() {
      if(this.state){
        return false; 
      }
      this.state = true;

      if (isEmpty(this.site_memo1)) {
        Toast('请正确法人电话!');
        this.state = false;
        return;
      }

      if (isEmpty(this.site_memo2)) {
        Toast('请正确填写负责人电话!');
        this.state = false;
        return;
      }

      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_updatebasic',
        qs.stringify({ 
          site_memo: this.site_memo, 
          site_memo1: this.site_memo1, 
          site_memo2: this.site_memo2, 
          site_memo3: this.site_memo3, 
          pic_name1: this.pic_name, 
          longitude: this.longitude, 
          latitude: this.latitude
        })
      );
      if (data.flag === '0') {
        this.state = false;
        Dialog.confirm({
          showCancelButton: false,
          message: "提交成功退出编辑！"
        }).then(async () => {
          this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
        });
      } else {
        this.state = false;
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 60px;
  padding-top: 20px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.bt {
  margin-left: 20px;
  &.rr {
    margin-right: 25px;
  }
  &.t {
    margin-top: 10px;
  }
}
.zczz {
  width: 360px;
}
.item1 {
  overflow: hidden;
  padding: 30px 0 28px;
  display: flex;
  position: relative;
  border-bottom: 1px solid #eee;
  .icon {
    width: 48px;
    height: 48px;
  }
  .title {
    width: 110px;
  }
  .text {
    color: #333;
  }
  .input {
    flex: 1;
    margin-left: 15px;
    border: 0;
    color: #333;
  }
  .imgzz {
    width: 460px;
    height: 460px;
    margin-left: 15px;
  }
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 20px;
  top: 25px;
  color: rgba(0, 49, 255, 1);
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}  
</style>